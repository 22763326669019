<template>
    <section>
        <modal ref="modalEditarGrupo" v-loading="loading" titulo="Editar Grupo" no-aceptar adicional="Añadir" @adicional="irValidar()">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center py-4">
                    <div class="col-9">
                        <ValidationProvider v-slot="{errors}" rules="required" name="Nombre">
                            <p class="f-12 text-general pl-2">Nombre del grupo</p>
                            <el-input v-model="model.nombre" size="small" placeholder="Nombre" maxlength="40" class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                        <div style="margin: 20px 0;" />
                        <ValidationProvider v-slot="{errors}" rules="required" name="Descripción">
                            <p class="f-12 text-general pl-2">Descripción</p>
                            <el-input
                            v-model="model.descripcion"
                            class="w-100"
                            size="small"
                            type="textarea"
                            :rows="5"
                            placeholder="Descripción"
                            maxlength="500"
                            />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>
<script>
import Referidos from "../../../services/clientesEstadisticas/referidos";
import { mapGetters } from 'vuex'

export default {
    components: {

    },
    data(){
        return{
            loading: false,
            id_grupo: null,
            model: {
                nombre: null,
                descripcion: null,
            }
        }
    },
    methods: {
        async irValidar(){
            const valid = await this.$refs.validacion.validate()
            if(!valid){
                return this.notificacion('Mensaje', 'Los campos son obligatorios', 'warning')
            }
            this.loading = true;
            this.putActualizarGrupo();
        },

        async putActualizarGrupo(){
            try {
                const { data } = await Referidos.putActualizarGrupo(this.model,this.id_grupo)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.limpiar();
                this.$emit('get')
                this.loading = false;
                this.$refs.modalEditarGrupo.toggle();
            } catch (error){
                this.error_catch(error)
            }
        },

        limpiar(){
            this.model = {
                nombre: null,
                descripcion: null,
            }
        },

        async toggle(info){
            this.id_grupo = info.id;
            this.model.nombre = info.nombre;
            this.model.descripcion = info.descripcion;
            this.$refs.modalEditarGrupo.toggle();
        },
    }
}
</script>
